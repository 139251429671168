<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { isMobile } from 'is-mobile'
import modulesMixin from '@/modules/Modules/modulesMixin'

export default {
  name: 'SignIn',
  components: {
    Action: () => import('@/components/general/Action'),
    InputField: () => import('@/components/general/InputField'),
    Logo: () => import('@/components/general/Logo')
  },

  mixins: [ modulesMixin ],

  data () {
    return {
      username: null,
      password: null,
      isDesktop: null
    }
  },

  validations: {
    username: {
      required
    },

    password: {
      required
    }
  },

  computed: {
    ...mapState([ 'accessibility' ]),
    ...mapGetters([ 'isUserRoot' ]),

    getClientName () {
      if (!process.env.VUE_APP_CLIENT_NAME) {
        return 'lite'
      }

      return process.env.VUE_APP_CLIENT_NAME
    }

  },

  created () {
    this.isDesktop = !isMobile()
  },

  methods: {
    ...mapActions([
      'setFeedback',
      'setFetching',
      'attemptSignIn',
      'attemptGetLoggedUser',
      'attemptGetActiveModulesList'
    ]),

    submit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        const { username, password } = this

        this.setFetching(true)

        this.attemptSignIn({
          username,
          password
        })
          .then(() => {
            this.attemptGetLoggedUser().then(() => {
              this.attemptGetActiveModulesList({
                query: {},
                filter: {},
                limit: 20
              }).then(() => {
                if (this.isUserRoot) {
                  this.$router.push({ name: 'modules.index' })
                } else {
                  this.createMenuTabManagement()
                  this.createMenuVertical()
                  const menu = this.VERTICAL_MENU_ITEMS[0].items
                    .filter((item) => !item.disabled && !item.bottom)

                  if (menu.length) {
                    this.$router.push({ name: menu[0].link?.name })
                  } else {
                    this.$router.push({ name: 'not.allowed' })
                  }
                }
              }).finally(() => {
                this.setFetching(false)
              })
            })
          })
          .catch(() => {
            this.setFeedback({ message: this.$t('global:auth.error') })
            this.resetForm()
            this.setFetching(false)
          })
      }
    },

    resetForm () {
      this.$v.$reset()
      this.username = null
      this.password = null
    }
  }
}
</script>

<template>
  <div class="auth">
    <div class="center clearfix">
      <div class="form">
        <form
          class="login-form"
          @submit.prevent="submit"
        >
          <div class="company">
            <Logo
              class="company-logo"
              :theme="$theme"
              dark
            />
            <!--
              <p class="company-headline" v-html="$t('auth.signin:company.description')"></p>
            -->
          </div>

          <h2>Bem-vindo à área administrativa da <b>Vitrinne {{ getClientName }}</b>!</h2>

          <InputField
            v-model="username"
            :label="$t('global:form.username')"
            type="text"
            autocomplete="username"
            autocapitalize="none"
            :validation="$v.username"
          />

          <InputField
            v-model="password"
            :label="$t('global:form.password')"
            type="password"
            autocomplete="current-password"
            :validation="$v.password"
          />

          <div class="form-submit">
            <Action
              v-if="isDesktop"
              type="button"
              :text="$t('auth.signin:form.submit')"
              submit
              primary
              fixed-width
            />
          </div>

          <div class="powered-by">
            <img
              class="logo-vitrinne"
              src="/assets/images/themes/lite/logo-dark.png"
              alt="Logo Vitrinne"
            > Powered by <img
              class="logo-dot"
              src="/assets/images/themes/lite/logo-dot-dark.svg"
              alt="Logo Dot"
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="@/modules/Auth/components/signin.scss"></style>
